body {
  background-color: #ffffff;
}
a {
  color: #f1b53d;
}
a:hover,
a:focus {
  color: #eaa211;
}
.mix {
  display: none;
}
.back-arrow {
  width: 35px;
}
.back-arrow svg {
  fill: #f1b53d;
}
.back-arrow:hover svg {
  fill: #eaa211;
}
.bmind {
  height: 80px;
  background-color: #2d251e;
}
.bmind a {
  color: rgba(255, 255, 255, 0.5);
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
}
.bmind a:hover {
  color: #f1b53d;
}
.bmind .avatar {
  background: none;
  margin-right: 10px;
  justify-content: flex-start;
}
.hidden {
  display: none;
}
.fa-lg {
  font-size: 1em;
}
.ml-15 {
  margin-left: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-40 {
  margin-bottom: 40px;
}
.pointer {
  cursor: pointer;
}
.status-tooltip {
  position: absolute;
  right: 10px;
  top: 13px;
  color: #bfc5cb;
}
.button-list {
  margin-left: 0;
}
.button-list a,
.button-list button {
  margin: 0;
}
.button-list a.btn-right,
.button-list button.btn-right {
  float: right;
}
.flex {
  display: flex;
  flex-flow: row nowrap;
}
.flex--center {
  align-items: center;
}
.flex--column {
  flex-flow: column wrap;
}
.fa-lg {
  vertical-align: 0%;
}
.alert-success {
  background-color: #5f9c3a;
  border-color: #5f9c3a;
  color: #fff;
}
.auth {
  background-color: #ecebeb;
  font-size: 16px;
  padding-bottom: 0 !important;
}
.auth .bmind {
  background: none;
  height: auto;
}
.auth .bmind a {
  color: rgba(255, 255, 255, 0.7);
  height: auto;
  padding: 0;
}
.auth .bmind a:hover {
  color: #f7bb31;
}
.auth.widescreen {
  padding-bottom: 0;
}
.auth h1,
.auth h2 {
  font-weight: 300;
  margin: 0;
}
.auth h1 {
  color: #f7bb31;
  padding-bottom: 10px;
  font-size: 36px;
}
.auth h2 {
  color: #8a8a8a;
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 18px;
}
.auth a {
  color: #f7bb31;
}
.auth a.btn-add {
  color: #473e36;
}
.auth a.btn-add:hover {
  color: #ffffff;
}
.auth__wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
  width: 600px;
  height: 100vh;
  padding: 0 20px;
  margin: 0 auto;
}
.auth__wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -245px;
  width: 490px;
  height: 100%;
  background-color: #473e36;
  z-index: -1;
}
@media only screen and (max-width: 600px) {
  .auth__wrap {
    width: 100%;
  }
}
@media only screen and (max-height: 560px) {
  .auth__wrap {
    height: 100%;
  }
  .auth__wrap .auth-block {
    margin: 30px 0;
  }
}
.auth .card {
  width: 100%;
  padding: 30px 64px 15px;
  margin: 0;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 600px) {
  .auth .card {
    padding: 25px;
  }
}
.auth form .control-label {
  color: #e2e2e2;
}
.auth form .form-group {
  margin-bottom: 25px;
}
.auth form .form-group.buttons {
  margin-top: 40px;
}
.auth form .form-group.has-error .form-control {
  border-bottom: 1px solid #e26464;
}
.auth form .form-group.has-error .help-block {
  display: inline-block;
  margin-top: 10px;
}
.auth form .form-group.has-error .help-block strong {
  font-weight: 300;
  color: #e26464;
}
.auth form ::-webkit-input-placeholder {
  color: #c7c7c7;
  font-size: 14px;
}
.auth form :-moz-placeholder {
  /* Firefox 18- */
  color: #c7c7c7;
  font-size: 14px;
}
.auth form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #c7c7c7;
  font-size: 14px;
}
.auth form :-ms-input-placeholder {
  color: #c7c7c7;
  font-size: 14px;
}
.auth form .remember {
  color: #575757;
  font-size: 14px;
  line-height: 24px;
}
.auth form .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 0;
  font-size: 16px;
  line-height: normal;
  padding-bottom: 12px;
  font-weight: 100;
}
.auth form .checkbox-custom input[type="checkbox"]:checked + label::before {
  background-color: #f7bb31;
  border-color: #f7bb31;
}
.auth form .checkbox label::before {
  margin-top: 4px;
}
.auth form .checkbox label::after {
  top: 0px;
}
.auth form .btn {
  padding-left: 40px;
  padding-right: 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.auth .alert {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}
.btn-color-picker {
  border-radius: 0 3px 3px 0px;
  background-color: #dddddd;
}
.btn-color-picker i {
  border: 1px solid #ffffff;
}
.btn {
  font-weight: 500;
}
.btn-upper {
  text-transform: uppercase;
}
.btn-documents {
  display: block;
  margin: 0 auto;
  font-weight: 400;
}
.btn-documents .btn-label {
  padding: 6px 10px;
}
.btn-delete {
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: none;
}
.btn-delete i {
  font-weight: normal;
}
.btn-add,
.jFiler-theme-default .jFiler-input-button {
  color: #2b3d51;
  background-color: #edeff0;
  border-color: #edeff0 !important;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}
.btn-add:hover,
.jFiler-theme-default .jFiler-input-button:hover {
  background-color: #f7bb31;
  color: #ffffff;
  border-color: #f7bb31 !important;
}
.btn-add .btn-label,
.jFiler-theme-default .jFiler-input-button .btn-label {
  margin-right: -14px;
  margin-left: 10px;
}
.btn-warning {
  background-color: #f7bb31;
  border-color: #f7bb31;
}
.files_holder {
  flex-flow: nowrap;
}
.files_holder .file-icon {
  width: 40px;
}
.files_holder .file-input {
  flex: 11 0 auto;
}
.files_holder .files_cell {
  display: flex;
  align-items: center;
}
.files_holder .files_cell--user {
  height: 140px;
  flex-flow: column;
  justify-content: center;
}
.files_holder .files_cell--user label,
.files_holder .files_cell--user .jFiler {
  width: 100%;
}
.files_holder .jFiler-theme-default .jFiler-input {
  margin: 0 !important;
}
.files_holder .col-md-1 {
  flex: 0 0 5.5%;
}
.files_holder .col-md-11 {
  flex: 0 0 90%;
}
.files_holder .col-xs-10 {
  flex: 0 0 80%;
}
.files_holder .jFiler-items-default .jFiler-item {
  margin: 10px 0 0;
}
.files_holder .jFiler-items-default .jFiler-item-custom {
  margin: 0;
}
.files_holder .jFiler-items-default .jFiler-item .jFiler-item-icon {
  display: none;
}
.files_holder .jFiler-items.jFiler-row {
  width: 100%;
}
.avatar {
  position: relative;
  background-color: #a5b1b6;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar--small,
.avatar--extra-small {
  width: 60px;
  height: 60px;
}
.avatar--small .icon,
.avatar--extra-small .icon {
  color: #ffffff;
  font-size: 20px;
}
.avatar--small img,
.avatar--extra-small img {
  width: auto;
  height: auto;
}
.avatar--extra-small {
  width: 36px;
  height: 36px;
}
.avatar--big {
  width: 140px;
  height: 140px;
}
.avatar--big .icon {
  position: relative;
  top: -3px;
  color: #ffffff;
  font-size: 40px;
}
.avatar--big img {
  height: 140px;
}
.card-box {
  padding: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}
.widget-user .text-muted,
.widget-user .text-muted a {
  color: #8c969b;
}
.widget-user .text-muted a:hover {
  text-decoration: underline;
}
.widget-user__box {
  display: flex;
  flex-flow: row;
  padding: 15px;
}
.widget-user__box .half {
  display: flex;
}
.widget-user__footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
}
.widget-user__footer .half {
  display: flex;
  flex: 0 0 50%;
  align-items: center;
  height: 35px;
}
.widget-user .wid-u-info {
  padding-left: 15px;
}
.widget-user .wid-u-info .fa,
.widget-user .wid-u-info .fa-lg {
  width: 14px;
  line-height: 21px;
}
.widget-user .wid-u-info--docs .fa,
.widget-user .wid-u-info--docs .fa-lg {
  width: auto;
  line-height: 21px;
}
.widget-user a h5 {
  color: #2b3d51;
  font-size: 16px;
}
.widget-user a h5 span {
  display: block;
}
.widget-user a h5:hover {
  color: #f7bb31;
}
.widget-user .active_docs {
  display: flex;
  text-transform: uppercase;
  color: #8c969b;
  font-weight: 500;
  font-size: 12px;
  padding-left: 11px;
}
.widget-user .active_docs:hover {
  color: #f7bb31;
}
.widget-user .active_docs .docs_count {
  font-size: 35px;
  line-height: 35px;
}
.widget-user .active_docs .docs_text {
  display: flex;
  align-items: center;
  line-height: 14px;
}
.form-control:focus {
  border-color: #f7bb31;
  outline: none;
}
.text-xs-right {
  justify-content: flex-end;
}
.page-title-box {
  margin: 0 0 20px;
  padding: 0 0 20px;
  box-shadow: 0 0 0 #000000;
  border-bottom: 1px solid #e1e8eb;
}
.page-title-box--tabs {
  margin: 0px 0 30px;
  padding: 0;
}
.page-title-box--tabs a {
  margin-right: 60px;
  text-transform: uppercase;
  font-weight: 500;
  color: #b5b6c3;
  line-height: 40px;
  border-bottom: 3px solid #ffffff;
  cursor: pointer;
}
.page-title-box--tabs a.active,
.page-title-box--tabs a:hover {
  color: #f7bb31;
}
.page-title-box--tabs a.active {
  border-bottom: 3px solid #f7bb31;
}
.page-title-box--tabs a span {
  font-size: 18px;
}
.page-title-box h1 {
  margin: 0;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
  color: #8c969b;
  text-transform: uppercase;
}
.page-title-box h2 {
  margin: 0;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  color: #8c969b;
}
.page-title-box h2 a {
  margin: 0 10px;
  color: #2b3d51;
  text-transform: uppercase;
}
.page-title-box h2 a:hover {
  color: #f7bb31;
}
.table thead th {
  vertical-align: top;
  padding: 10px 25px 10px 10px !important;
}
.table thead th.pr-10 {
  padding-right: 10px !important;
}
table.dataTable thead th {
  font-weight: 500;
  line-height: 18px;
  font-size: 13px;
  padding-right: 20px;
}
table.dataTable thead th.sorting:after {
  top: 10px;
}
table.dataTable thead th.sorting_asc,
table.dataTable thead th.sorting_desc {
  background: #f7bb31;
  color: #ffffff;
}
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  color: #ffffff;
  opacity: 1;
}
table.dataTable thead th.sorting_asc:after {
  top: 14px;
}
table.dataTable thead th.sorting_desc:after {
  top: 7px;
}
.table td,
.table th {
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;
}
.table td a,
.table th a {
  color: #2b3d51;
}
.table td a:hover,
.table th a:hover {
  color: #f7bb31;
}
.dot {
  font-size: 10px !important;
}
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label,
.page-item.disabled .page-link,
.page-item.disabled .page-link:focus,
.page-item.disabled .page-link:hover {
  color: #a5b1b6;
}
div.dataTables_wrapper div.dataTables_info strong,
div.dataTables_wrapper div.dataTables_length label strong,
div.dataTables_wrapper div.dataTables_filter label strong,
.page-item.disabled .page-link strong,
.page-item.disabled .page-link:focus strong,
.page-item.disabled .page-link:hover strong {
  color: #2b3d51;
}
.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  background-color: #f7bb31;
  border-color: #f7bb31;
}
.modal-dialog--files {
  width: 700px;
}
.modal-dialog--files .modal-header {
  border-bottom: none;
}
.modal-dialog--files .modal-body {
  padding: 0;
  max-height: 353px;
  overflow-y: auto;
}
.modal-dialog--files .files-header__cell {
  padding: 15px 20px;
  width: 33.33%;
  background: #f6f7f7;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.modal-dialog--files .files-header__cell:last-of-type {
  border-right: none;
}
.modal-dialog--files .files-header__cell strong {
  line-height: 16px;
}
.modal-dialog--files .files-header__cell span {
  display: block;
  line-height: 25px;
}
.modal-dialog--files .files-list {
  padding: 0 20px 20px;
}
.modal-dialog--files .files-list__file {
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-dialog--files .files-list__file .fa {
  font-size: 25px;
  color: #879197;
  margin-right: 20px;
}
.modal-dialog--files .files-list__file .btn:hover {
  color: #ffffff;
}
.modal-dialog--files .files-list__file a {
  color: #f1b53d;
}
.modal-dialog--files .files-list__file a:hover,
.modal-dialog--files .files-list__file a:focus {
  color: #eaa211;
}
.modal-dialog--files .files-list__file .cell_50 {
  width: 50%;
}
.modal-dialog--files .files-list__file .cell_25 {
  width: 25%;
  height: 100%;
  text-align: center;
  border-left: 1px solid #e5e5e5;
  justify-content: center;
}
.modal-dialog--files .files-list__file .cell_25:last-of-type {
  border-right: 1px solid #e5e5e5;
}
.modal-dialog--files .files-list__file .cell_25.no-border {
  border: none;
}
.modal-dialog--comments {
  width: 700px;
}
.modal-dialog--comments .modal-body {
  padding: 20px;
  max-height: 353px;
  overflow-y: auto;
}
.modal-dialog--comments .form-group {
  width: 100%;
}
.modal-dialog--comments .form-group textarea {
  box-sizing: border-box;
  width: 100% !important;
}
.navbar::after {
  display: none;
}
.breadcrumb {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  background: transparent;
}
.breadcrumb li {
  line-height: 58px;
}
.breadcrumb li a {
  color: #334455;
  font-weight: 500;
}
.breadcrumb li a:hover {
  color: #f7bb31;
}
.navbar-custom .navbar-nav .nav-link {
  line-height: 58px;
}
.notification-list .noti-icon,
.button-menu-mobile {
  color: #9fb4bf;
}
.notification-list .noti-icon {
  font-size: 26px;
}
.topbar .topbar-left,
.navbar-custom {
  height: 60px;
}
.navbar-custom {
  background: #ffffff;
  border-bottom: 1px solid #e1e8eb;
}
.button-menu-mobile {
  line-height: 60px;
}
.notification-list .noti-icon-badge {
  height: 15px;
  width: auto;
  top: 14px;
  left: 23px;
  right: auto;
  border: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 3px;
}
.side-menu {
  top: 0px;
  box-shadow: 0 0 0;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.side-menu.left {
  position: fixed;
}
.topbar-left {
  height: 107px;
}
.topbar-left .logo {
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: #473e36;
}
.topbar-left .logo:hover {
  background-color: rgba(45, 37, 30, 0.4);
}
.topbar-left .logo .big {
  margin-top: -9px;
  margin-left: 23px;
}
.topbar-left .logo .small {
  display: none;
}
#wrapper.enlarged .topbar-left .logo {
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}
#wrapper.enlarged .topbar-left .logo .big {
  display: none;
}
#wrapper.enlarged .topbar-left .logo .small {
  display: block;
}
.topbar .topbar-left {
  text-align: left;
  padding-left: 25px;
}
#sidebar-menu > ul > li > a,
#sidebar-menu ul ul a {
  color: #d8bda0;
  position: relative;
}
#sidebar-menu > ul > li > a:hover,
#sidebar-menu ul ul a:hover {
  color: #ffffff;
}
#sidebar-menu > ul > li > a.active,
#sidebar-menu ul ul a.active {
  color: #fff !important;
  background-color: #2d251e;
}
#sidebar-menu > ul > li > a.active:after,
#sidebar-menu ul ul a.active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: #e08c27;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > a {
  color: #fff !important;
  background-color: #2d251e;
}
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul > li:hover a,
#wrapper.enlarged .left.side-menu #sidebar-menu ul > li:hover > ul > li.active > a {
  color: #2d251e !important;
}
#sidebar-menu ul ul li.active a,
#sidebar-menu .subdrop {
  color: #ffffff !important;
}
.menu-title {
  padding-left: 25px !important;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600 !important;
}
figure {
  margin: 0;
}
.logo {
  height: 60px;
  color: #ffffff !important;
}
.notification-list .noti-title {
  background-color: #473e36;
}
.navbar-custom .navbar-nav .nav-link.nav-user {
  display: flex;
  height: 58px;
  align-items: center;
}
.dropdown-arrow::before {
  border-bottom: 10px solid #473e36;
}
.dropdown-menu {
  box-shadow: 0 0 24px 0 rgba(43, 61, 81, 0.4), 0 1px 0 0 rgba(43, 61, 81, 0.02);
}
.notification-list .notify-item {
  padding: 10px 15px;
}
.notification-list .notify-item .notify-icon {
  height: 30px;
  width: 30px;
  line-height: 32px;
  margin-top: 5px;
}
.dropdown-item,
.notification-list .notify-item .notify-details {
  white-space: normal;
}
.notification-list .notify-item .notify-details {
  white-space: normal;
  line-height: 18px;
}
.bg-comment {
  color: #ffffff;
  background-color: #ceac36 !important;
}
.bg-new_file {
  color: #ffffff;
  background-color: #538492 !important;
}
.bg-status {
  color: #ffffff;
  background-color: #5a6995 !important;
}
.bg-new_document {
  color: #ffffff;
  background-color: #7B843D !important;
}
.ntf-holder {
  max-height: 300px;
  overflow-y: auto;
}
.button-menu-mobile:hover {
  color: #473e36;
}
.comments {
  padding-left: 30px;
}
@media only screen and (max-width: 980px) {
  .comments {
    padding: 0;
  }
}
.inbox-widget .inbox-item .inbox-item-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.inbox-widget .inbox-item .inbox-item-img .avatar--small {
  width: 40px;
  height: 40px;
}
.new-notification-text {
  line-height: 60px;
  color: #ff5d48;
  text-transform: uppercase;
  font-weight: 500;
}
.inbox-widget .inbox-item .inbox-item-date {
  top: 10px;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #f7bb31;
  border-color: #f7bb31;
}
.checkbox label::after {
  left: 1px;
}
.green {
  color: #77a63b;
}
.red {
  color: #ff6b6b;
}
#wrapper.enlarged .topbar .topbar-left {
  text-align: center;
  padding: 0;
}
#wrapper.enlarged .topbar .topbar-left .logo {
  justify-content: center;
}
.side-menu,
body.fixed-left .side-menu.left {
  padding-bottom: 0;
}
.sidebar-inner {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #473e36;
}
.sidebar-inner .csi-contacts {
  padding-left: 25px;
  margin-bottom: 40px;
}
.sidebar-inner .csi-contacts .avatar--small {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background: none;
}
.sidebar-inner .csi-contacts h2 {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #f1b53d;
  margin-bottom: 15px;
}
.sidebar-inner .csi-contacts .contact-name {
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
}
.sidebar-inner .csi-contacts .wid-u-info .text-muted,
.sidebar-inner .csi-contacts .wid-u-info .text-muted a {
  color: #bdc3c7;
  font-size: 13px;
}
.sidebar-inner .csi-contacts .wid-u-info .text-muted .icon,
.sidebar-inner .csi-contacts .wid-u-info .text-muted a .icon {
  font-weight: 500;
  width: 15px;
  font-size: 14px;
  text-align: center;
}
.sidebar-inner .csi-contacts .wid-u-info .text-muted .icon--email,
.sidebar-inner .csi-contacts .wid-u-info .text-muted a .icon--email {
  font-size: 13px;
}
.sidebar-inner .csi-contacts .wid-u-info .text-muted.csi-address .icon,
.sidebar-inner .csi-contacts .wid-u-info .text-muted a.csi-address .icon {
  margin-top: 3px;
}
.sidebar-inner .csi-contacts .wid-u-info .text-muted.csi-address span,
.sidebar-inner .csi-contacts .wid-u-info .text-muted a.csi-address span {
  display: inline-block;
  padding-left: 3px;
}
.sidebar-inner .csi-contacts a:hover {
  text-decoration: underline;
}
#wrapper.enlarged .sidebar-inner {
  padding-bottom: 0px;
}
#wrapper.enlarged .sidebar-inner .csi-contacts {
  display: none;
}
#wrapper.enlarged .sidebar-inner .bmind a {
  padding-left: 0;
  justify-content: center;
}
#wrapper.enlarged .sidebar-inner .bmind a span {
  display: none;
}
#wrapper.enlarged .sidebar-inner .bmind .avatar {
  margin-right: 0;
}
.content-page > .content {
  margin-top: 60px;
}
